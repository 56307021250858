.teamPage_team_container {
    font-family: MyFont Rg;
    text-transform: uppercase;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 4%;
    margin-bottom: 10%;
}

.teamPage_team_header_container {
    display: flex;
    width: 100%;
}

.teamPage_tabs_container {
    display: flex;
    margin-bottom: 7%;
    margin-top: 7%;
}

.teamPage_team_header_logo_container {
    width: 25%;
    display: flex;
}

.teamPage_team_header_info {
    display: flex;
    width: 75%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.teamPage_team_header_info_titles {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 30px;
}

.team_editButton {
    border: 1px solid #45FFA6;
    border-radius: 10px;
    background-color: #45FFA6;
    color: #222222;
    font-size: 10px;
    font-family: Proxima Nova Rg;
    font-weight: bolder;
    transition: all 500ms ease;
    padding: 3px 5px;
    cursor: pointer;
    &:hover {
        background-color: transparent;
        color: #45FFA6;
    }
}

.teamPage_team_name {
    position: relative;
    cursor: default;
}

.teamPlayers_player_titles {
    margin: 0;
    padding: 0;
    font-size: 35px;
    letter-spacing: 2px;
    color: #10D4FF;
}
.teamPlayers_player_titles.big {
    font-size: 25px;
}

.teamPage_team_header_info_titles .teamPage_team_name {
    color: #10D4FF;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.teamPage_team_nameTitle {
    position: relative;
    width: auto;
    white-space: nowrap; 
    overflow: hidden;
    font-size: 60px;
}

.teamPage_team_header_info_titles p {
    color: #939393;
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.teamPage_team_header_info_titles h3:first-child {
    color: #10D4FF;
    width: 33.33%;
    font-size: 30px;
    padding-right: 10px;
}

.teamPage_team_header_info_titles h3 {
    text-transform: uppercase;
    font-size: 23px;
    width: 33.33%;
    letter-spacing: 1px;
    margin-bottom: 2%;
}

.teamPage_team_header_full_info {
    width: 100%;
    display: flex;
}

.teamPage_team_header_full_info div {
    display: flex;
    justify-content: flex-start;
}

.teamPage_team_header_full_info_aboutTeam {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.teamPage_team_header_full_info_lastTournaments {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.teamPage_team_header_full_info_lastTournaments span {
    font-family: Proxima Nova Rg;
}

.teamPage_team_header_full_info_aboutTeam_item:last-child {
    margin-bottom: 0;
}

.teamPage_team_header_full_info_aboutTeam_item span {
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.teamPage_team_header_full_info_aboutTeam_item span:first-child {
    color: #939393;
    width: 30%;
}

.teamPage_team_header_full_info_socials {
    width: 38%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.teamPage_team_header_full_info_socials_container {
    border: 0.5px solid #45FFA6;
    border-radius: 5px;
    background-color: #3c3c3c;
    width: 50%;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
}

.teamPage_team_header_full_info_socials_container:first-child {
    margin-left: 0;
}

.teamPage_team_header_full_info_stats {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.teamPage_team_header_logo {
    width: 250px;
    height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: #45FFA6 solid 1px;
    border-radius: 50%;
}

.teamPage_steam_icon {
    color: #fff;
    transition: color 500ms linear;
    &:hover{
        color: #171D25;
    }
}

.teamPage_discord_icon {
    color: #fff;
    transition: color 500ms linear;
    &:hover{
        color: #7289da;
    }
}

.teamPage_telegram_icon {
    color: #fff;
    transition: color 500ms linear;
    &:hover{
        color: #2AABEE;
    }
}

.teamPage_tab {
    font-family: Proxima Nova Rg;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 5px;
    background-color: #222222;
    color: white;
    font-weight: 500;
    padding: 2px 7px;
    border: none;
    box-shadow: 0px 0px 4px 0px #45FFA6;
    margin-right: 10px;
    
    &:hover {
        background-color: #45FFA6;
        color: #222222;
        font-weight: 600;
    }
}

.teamPage_tab.teamPage_active_tab {
    background-color: #45FFA6;
    color: #222222;
    font-weight: 600;
}