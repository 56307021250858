/* ------- container ------- */

.elimination_mainContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
}

.elimination_bracket {
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: stretch;
    margin-bottom: 100px;
    justify-content: flex-start;
}

.elimination_bracket.lowerBracket {
    margin-bottom: 0px;
}

/* ------- round ------- */

.elimination_round {
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
    justify-content: space-around;
    margin-right: 75px;
    &:last-child {
        margin-right: 0px;
    }
}

.elimination_roundNameContainer {
    width: 100%;
    display: flex;
    align-items: stretch;
    margin-bottom: 70px;
    justify-content: flex-start;
}

.elimination_roundName {
    margin-right: 75px;
    text-align: center;
    background-color: #404040;
    width: 120px;
    font-size: 10px;
    letter-spacing: 2px;
    border-radius: 3px;
    border: 1px solid #7a7a80;
    height: 50px;
    &:last-child {
        margin-right: 0px;
    }
}

.elimination_bracketInfo {
    width: 100%;
}

.elimination_bracketName {
    margin-bottom: 30px;
}

.contentContainer {
    cursor: grab;
}

.contentContainer:active {
    cursor: grabbing;
}