/* ------- group ------- */

.roundRobin_group {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #45FFA6;
    padding: 35px;
    border-radius: 5px;
    margin-bottom: 90px;
    &:last-child {
        margin-bottom: 0;
    }
}

.roundRobin_group.max {
    width: 1000px;
}

/* ------- round ------- */

.roundRobin_round {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    justify-content: space-evenly;
}

.roundRobin_roundUpper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 30px;
}

.roundRobin_roundLower {
    width: 100%;
}

.roundRobin_roundLower hr {
    background-color: #7a7a80;
    width: 100%;
}

.robin_groupNameContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.robin_groupName {
    text-align: center;
    background-color: #404040;
    width: 100%;
    font-size: 15px;
    letter-spacing: 1px;
    border-radius: 3px;
    border: 1px solid #7a7a80;
    height: 50px;
}

/* ------- table ------- */

.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Arial, sans-serif;
}

.roundRobin_resultGrid_header {
    background-color: #404040;
    font-weight: bold;
}

.row {
    display: flex;
}

.roundRobin_resultGrid_cell {
    flex: 1;
    padding: 10px;
    border: 1px solid silver;
    text-align: center;
}