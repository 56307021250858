.navbar_ {
    font-family: Proxima Nova Rg;
    font-weight: 100;
    font-size: 85%;
    z-index: 5000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 10%;
    z-index: 1001;
    padding: 1% 5%;
    transition: background-color 400ms linear;
}

.navbar_scrolled {
    background-color: #252525;
}

.navbar_flexible_parts {
    width: 100%;
}

.navbar_logo {
    width: 90%;
}

.nav_links {
    display: flex;
    align-items: flex-end;
}

.navbar_link {
    margin-right: 20%;
    transition: all 700ms ease;

    &:hover {
        color: #45FFA6;
    }
}

.navbar_link:first-child {
    margin-left: 15%;
    transition: all 700ms ease;

    &:hover {
        color: #45FFA6;
    }
}

.navbar_link:last-child {
    margin-right: 0;
    transition: all 700ms ease;

    &:hover {
        color: #45FFA6;
    }
}

.nav-link {
    display: block;
    padding: 0
}

.navbar-expand-lg .navbar-collapse {
    width: 100%;
    justify-content: space-between !important;
    align-items: flex-end;
}

.navbar_bell_container {
    position: relative;
}

.navbar_notif_dot {
    background-color: rgb(202, 52, 52);
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20%;
}

.navbar_bell {
    font-size: 25px;
    cursor: pointer;
}

.notification_wrapper {
    position: relative;
    display: none;
}

.notification_wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    right: 3px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent rgb(235, 235, 235) transparent;
    z-index: 999;
}

.notification_wrapper.visible {
    display: block;
}


/*--------------------- BUTTONS ---------------------*/


.nav_button_container navbar-nav {
    width: 30%;
}

.auth_button_container {
    display: flex;
}


.navbar_reg_btn {
    font-weight: 600;
    color: #252525;
    background-color: #45FFA6;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    padding: 9px 13px;
    margin-right: 25px;
    cursor: pointer;
    box-shadow: #252525 4px 4px 0 0, #45FFA6 4px 4px 0 1px;
    transition: all 250ms ease-in-out;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;

    &:hover {
        color: #252525;
        background-color: #45FFA6;
        border-color: #45FFA6;
        box-shadow: transparent 4px 4px 0 0;
        transform: translate(2px, 2px);
    }
}

.navbar_login_btn {
    font-weight: 600;
    color: #45FFA6;
    background-color: transparent;
    border: 1px solid #45FFA6;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: #252525 4px 4px 0 0, #45FFA6 4px 4px 0 1px;
    transition: all 250ms ease-in-out;
    padding: 9px 13px;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;

    &:hover {
        color: #45FFA6;
        background-color: transparent;
        border-color: #45FFA6;
        box-shadow: transparent 4px 4px 0 0;
        transform: translate(2px, 2px);
    }
}


/*--------------------- MEDIA ---------------------*/


@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        width: 100%;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center;
        transition: height 0.5s ease;
    }
}


/*--------------------- USER ---------------------*/


.navbar_acc_container {
    height: auto;
    border: 1px solid #45FFA6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3px 10px;
    background-color: rgba(38, 38, 38, 0.564);
}

.navbar_acc_img_container {
    width: 35px;
    height: 35px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.navbar_acc_img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #343434;
    object-fit: cover;
}

.navbar_acc_nickname_container {
    margin: 5px 15px;
    min-width: 130px;
    cursor: default;
}

.navbar_acc_nickname {
    font-size: 20px;
    font-family: Proxima Nova Rg;
    color: #45FFA6;
    display: flex;
    justify-content: center;
    > span {
        cursor: pointer;
    }
}

.navbar_acc_link:hover {
    text-decoration: none;
}

.navbar_acc_mainContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    gap: 25px;
}

.navlink_logout {
    text-decoration: none;
    cursor: pointer;
    margin-left: 5%;
}

.navbar-expand-lg .navbar-nav .nav-link {
    text-decoration: none;
}

.navbar_link_admin {
    font-weight: 900;
    color: #45FFA6;
    font-size: 14px;
}

/* ------------ dropdown ------------ */

.dropdown_menu {
    border: 1px solid #45FFA6;
    background-color: #343434;
    z-index: 1;
    border-radius: 5px;
    top: 50px;
    user-select: none;
    position: absolute;
    right: 0;
    padding: 0 20px;
}

.dropdown_item {
    font-size: 12px;
    text-align: center;
    padding: 12px 0;
    text-decoration: none;
    display: block;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    transition: all 300ms ease;
    font-family: Nasalization Rg;
    letter-spacing: 1px;
    
    &:hover {
        text-decoration: none;
        color: #45FFA6;
        background-color: #343434 !important;
    }
    
    &:focus {
        text-decoration: none;
        color: #45FFA6;
        background-color: #343434 !important;
    }
}

.dropdown_item.disabled {
    text-decoration: none;
    color: rgb(102, 104, 103);
    background-color: #343434 !important;
    cursor: default;
}

.dropdown_item.logOut {
    color: red;
}

.dropdown_item:hover {
    background-color: #f1f1f1;
}

.navBar_dropdownIcon {
    cursor: pointer;
    font-size: 30px !important;
    color: rgb(219, 219, 219);
    
    &:hover {
        color: white;
    }
}

.navbar-toggler,
.navbar-toggler:active,
.navbar-toggler:focus {
    border: none;
    box-shadow: none;
    padding: 10px;
}

.navbar_lobbiesBtn {
    cursor: pointer;
}

@media (max-width: 900px) {
    .navbar-expand-lg .navbar-collapse {
        width: 100%;
        align-items: end;
    }
    
    .navbar-nav {
        text-align: end;
    }
    
    .navbar_link {
        margin-right: 0;
        transition: all 700ms ease;
    }
}