.user_general_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-family: Nasalization Rg;
    margin-top: 40px;

    h5 {
        font-family: Proxima Nova Rg;
        color: #fff;
        font-weight: 500;
        font-size: 24px;
        padding-left: 20px;
        width: 100%;
        text-align: start;
    }
}

.user_spinnerContiner {
    height: 185px;
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_playerGamesProfiles_tabs {
    width: 100%;
    display: flex;
    margin: 50px 0 50px 0;
    gap: 20px;
}

.user_playerGamesProfiles_tab {
    padding: 10px 10px;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #45FFA6;
    width: 20%;
}

.user_playerGamesProfiles_tab {
    padding: 10px 10px;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #45FFA6;
    width: 20%;
    font-size: 18px;
    font-weight: 600;
}

.user_playerGamesProfiles_tab.active {
    background-color: #45FFA6;
    color: #222;
}

/*---------- USER FIRST CONTAINER ----------*/


.user_first_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.userCard_container {
    width: 650px;
    height: 230px;
    padding: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.userCard_secondContainer {
    width: 45%;
    max-height: 280px;
    border-radius: 15px 0px;
    transition: width 1s ease, height 0.55s ease;
    position: relative;
}

.userCard_secondContainer {}

.userCard_secondContainer_header {
    width: 100%;
    height: 35%;
    text-align: center;
}

.userCard_secondContainer_headerTitle {
    width: 30%;
    font-size: clamp(10px, 2vw, 25px);
}

.userCard_secondContainer_headerLevl {
    width: 100%;
    text-align: center;
    font-size: 25px;
}

.userCard_secondContainer_headerLevl span {
    color: #45FFA6;
    ;
}

.userCard_secondContainer_center {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.userCard_secondContainer_centerCircle {
    border-radius: 50%;
    border: 7px solid #45FFA6;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userCard_secondContainer_centerCircleContainer {
    width: 50%;
    display: flex;
    justify-content: center;
}

.userCard_secondContainer_playerTasksContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userCard_secondContainer_playerTasksTitle {
    font-size: 9px;
    color: #8E8E8E;
    text-align: start;
}

.userCard_secondContainer_playerTask {
    font-size: 11px;
    text-align: start;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.userCard_secondContainer_playerTask {
    font-size: 11px;
    text-align: start;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
}

.userCard_secondContainer_playerTask span {
    width: 70%;
}

.userCard_secondContainer_playerTaskCircle {
    border-radius: 50%;
    border: 3px solid #C11328;
    width: 15px;
    height: 15px;
}

.userCard_secondContainer_playerTaskCircle.done {
    border-radius: 50%;
    border: 3px solid #45FFA6;
    width: 13px;
    height: 13px;
}

.userCard_avatarContainer {
    width: 26%;
    margin-right: 4%;
    border-radius: 50%;
    position: relative;
}

.input_avatar_upload {
    cursor: pointer;
    color: #45FFA6;
    font-size: 25px;
    position: absolute;
    left: 0;
}

.input_avatar {
    visibility: hidden;
    position: absolute;
}

.userCard_avatar {
    width: 100%;
    height: 158px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #343434;
}

.userCard_infoContainer {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.userCard_firstInfoStatContainer {
    display: flex;
}

.userCard_infoNameContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;

    & :is(h3, h5) {
        line-height: normal;
    }
}

.userCard_infoNameBadge {
    display: flex;
    align-items: center;
    gap: 5px;
}

.userCard_userInfoTeamName {
    color: #707070;
    cursor: pointer;
}

.userCard_infoSocialContainer {
    width: 35%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
}

.input_socials {
    cursor: pointer;
    color: #45FFA6;
    background-color: #313131ca;
    position: absolute;
    font-size: 21px;
    display: flex;
}

.userCard_verificationLink {
    width: 100%;
    padding: 1%;
    background-color: #45FFA6;
    color: #000 !important;
    border-radius: 5px;
    margin-top: 5%;
    cursor: pointer;
    text-decoration: none !important;
}

.userCard_infoNickName {
    font-size: 29px;
    letter-spacing: 1px;
    cursor: default;
}

.userCard_secondInfoStatContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.userCard_stat {
    display: flex;
    width: 30%;
    justify-content: flex-start;
}

.userCard_statNumber {
    color: #45FFA6;
}

.userCard_statText {
    color: #747474;
}

.expand_icon {
    position: absolute;
    margin: 1%;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.userHeader_edit_icon {
    position: absolute;
    margin: 1%;
    top: 0;
    right: 0;
    cursor: pointer;
}

.userHeader_edit_icon .startEditing:hover {
    transition: color 300ms ease;
    color: #45FFA6;
}

.userHeader_edit_icon .stopEditing:hover {
    transition: color 300ms ease;
    color: rgba(202, 22, 22, 0.872);
}

.user_second_containerTitle {
    text-align: start;
    color: #CECECE;
    font-size: 20px;
}


.userCard_fullUserINfoContainer form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.userCard_infoBlock {
    width: 30%;
    height: 45px;
    margin: 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & :is(span) {
        color: #A9A9A9;
        font-size: 12px;
    }

    & :is(div) {
        padding: 2% 3%;
        letter-spacing: 1px;
        color: #fff;
        background-color: #303030;
        border-radius: 5px;
        width: 100%;
        text-align: start;
    }

    & :is(input) {
        padding: 2% 3%;
        letter-spacing: 1px;
        color: #fff;
        background-color: #303030;
        border-radius: 5px;
        width: 100%;
        text-align: start;
        border: none;

        &:focus {
            background-color: #505050;
            outline: none;
            color: #ffffff;
        }
    }
}

.userCard_infoBlock.streamerCheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
}

.userCard_infoBlock input[type="checkbox"] {
    cursor: pointer;
    margin-right: 10px;
    width: 1.2em;
    height: 1.2rem;
    accent-color: #45FFA6;
}


/*---------- USER SECOND CONTAINER ----------*/


.team_info_header {
    font-size: 24px;
    color: #707070;
    text-align: left;
    margin: 60px 0px 30px;
    font-family: Nasalization Rg;
    display: flex;
}

.team_info_teamname {
    color: #10D4FE;
    cursor: pointer;
    padding: 0px 5px 0px 15px;
}

.team_info_edit {
    font-size: 15px;
    cursor: pointer;
    color: grey;
}

/*---------- USER CREATE LOBBY ----------*/


.create_lobby_container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}


/*---------- USER TEAM MEMBER CARD ----------*/


.team_member_card {
    width: 19%;
    margin-right: 20px;
}

.team_member_unit {
    display: flex;
    border: 1px solid #45FFA6;
    box-shadow: 0 0 5px #45FFA6;
    padding: 10px;
    margin-bottom: 15px;
    height: 80px;
    align-items: center;
}

.team_member_unit_imgContainer {
    width: 25%;
}

.team_member_unit_imgContainer img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    object-fit: cover;
}

.team_member_unit_captain {
    width: 10%;
}

.team_member_unit_name_x {
    font-size: 30px;
    cursor: pointer;
}

.team_member_unit_name_x:hover {
    color: #b68ee5;
}


/*---------- USER TEAM MEMBER UNIT STAT ----------*/


.team-member-unit-stat {
    border: 1px solid #45FFA6;
    box-shadow: 0 0 5px #45FFA6;
    padding: 5px;
    height: 200px;
}


/*---------- USER ADD TEAM MEMBER ----------*/

.create_team_button_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_team_button {
    color: #252525;
    font-weight: 600;
    background-color: #45FFA6;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 4%;
    margin-top: 4%;
    cursor: pointer;
    transition: all 350ms;

    &:hover {
        color: #45FFA6;
        border-color: #45FFA6;
        background-color: transparent;
    }
}


/*---------- USER TEAM MEMBERS ----------*/


.team_members_container {
    display: flex;
    width: 100%;
}

.team_member_unit_name_container {
    display: flex;
    align-items: center;
    width: 65%;
}

.team_member_unit_stat {
    border: 1px solid #45FFA6;
    box-shadow: 0 0 5px #45FFA6;
    padding: 5px;
    height: 200px;
}

.team_member_unit_name {
    width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 16px;
    transition: all 300ms ease-in;
    text-align: center;
}

.team_member_unit_name:hover {
    color: #45FFA6;
}


/*---------- USER THIRD CONTAINER ----------*/




.user_secondary_headers {
    font-family: Nasalization Rg;
    font-size: 20px;
    color: #cecece;
    text-align: left;
    margin: 30px 0 20px 0;
}

.game_history_container {
    display: flex;
}


/*---------- USER FOURTH CONTAINER ----------*/


.user_third_container {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: Proxima Nova Rg;
    margin-bottom: 100px;
}

.user_game_units_container {
    display: flex;
    flex-direction: row;
}

.user_game_unit_stat {
    font-size: 125%;
    padding: 10px 30px;
    background-color: #292929;
    width: auto;
    border: none !important;
    color: white;
    font-weight: 900;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px -3px 5px 0px #00000017, 0px 12px 13px 0px #0000002B, 0px 4px 6px 0px #0000001F, 0px -12px 30px 0px #0000001F, 0px 54px 55px 0px #00000040;
    transition: all 700ms ease;

    &:hover {
        background-color: #45FFA6;
        color: #222222;
    }
}

.user_game_unit_stat.active {
    background-color: #45FFA6;
    color: #222222;
}

.user_game_unit_stat.disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
        background-color: #292929;
        color: white;
    }
}

.second_level_header {
    font-size: 20px;
    color: #cecece;
    margin-bottom: 10px;
    font-family: Nasalization Rg;
}

.second_level_header.resTable {
    text-align: center;
}

/*---------- USER STAT LEVEL 2 ----------*/

.user_stat_second_level2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    font-family: Nasalization Rg;
}

/*---------- USER STAT MINI TABLE ----------*/

.user_stat_mini_table {
    min-height: 185px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* background-color: #292929; */
    border-radius: 10px;
    box-shadow: 0px -3px 5px 0px #00000017, 0px 12px 13px 0px #0000002B, 0px 4px 6px 0px #0000001F, 0px -12px 30px 0px #0000001F, 0px 54px 55px 0px #00000040;
}

.user_stat_mini_table_unit {
    width: 45%;
    height: 80px;
    /* background-color: #292929; */
    border-radius: 8px;
    margin: 5px;
    padding: 2px;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.user_stat_mini_table_unit_info {
    font-size: 30px;
    height: 80%;
    color: #45FFA6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_stat_mini_table_unit_info.blue {
    color: #10D4FF;
}

.user_stat_mini_table_unit_header {
    font-size: 10px;
}

/*---------- USER FIFTH CONTAINER ----------*/

.user_fifth_container {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: Proxima Nova Rg;
    margin-bottom: 70px;
}

.user_maps_container {
    width: 100%;
    display: flex;
}

.user_map_unit {
    height: 127px;
    border-radius: 10px;
    box-shadow: 0px -3px 5px 0px #00000017, 0px 12px 13px 0px #0000002B, 0px 4px 6px 0px #0000001F, 0px -12px 30px 0px #0000001F, 0px 54px 55px 0px #00000040;
    margin-right: 20px;
    position: relative;
}

.user_map_unit_label {
    position: absolute;
    top: 15px;
    right: 0;
    height: 42px;
    width: 100px;
    background: #45FFA6;
    padding: 5px;
    border-radius: 8px 0 0 8px;
    z-index: 2;
    font-size: 25px;
    text-align: center;
    font-weight: 800;
    color: rgb(56, 56, 56);
}

.user_map_unit_pic_container {
    position: relative;
    display: inline-block;
}

.user_map_unit_pic_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.user_map_unit_pic {
    height: 100%;
    display: flex;
    width: 100%;
    border-radius: 10px;
}

.user_maps_container .user_map_unit:last-child {
    margin-right: 0;
}