.bracket_editMatchModal_container .modal-content {
    background-color: #404040 !important;
    color: #fff;
    box-shadow: 0px 4px 10px 2px #45FFA6;
    border-radius: 7px;
}  

.bracket_editMatchModal_container input, select, .form-control {
    margin-bottom: 5%;
    transition: all 500ms ease;
    &:hover {
        border-color: #45FFA6;
    }
}

.bracket_editMatchModal_container .form-control:active,
.bracket_editMatchModal_container .form-control:focus {
    box-shadow: none;
    border: 1px solid #45FFA6;
}

.bracket_editMatchModal_container input::placeholder, select::placeholder {
    color: #404040;
}

.bracket_editMatchModal_container .editMatchModal_buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.bracket_editMatchModal_container .editMatchModal_buttonContainer button {
    background-color: #45FFA6;
    border-radius: 5px;
    border: 1px solid #45FFA6;
    width: 25%;
    height: 35px;
    font-weight: bold;
    transition: all 500ms ease;
    color: #404040;
    &:hover {
        background-color: transparent;
        color: #45FFA6;
    }
}

.bracket_editMatchModal_container .matchDate {
    width: 90% !important;
}