.doubleElimination_upperBracket {
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: stretch;
    margin-bottom: 0%;
    justify-content: flex-start;
    gap: 150px;
}

.doubleElimination_lowerBracket {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 150px;
}