.brackets_match {
    padding: 9% 12% 8% 10%;
    border: 1px solid #7a7a80;
    border-radius: 3px;
    background-color: #404040;
    position: relative;
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    box-shadow: 1px 6px 15px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.match_date {
    font-size: 10px;
    color: #7a7a80;
    text-align: center;
    margin-top: 3px;
    input {
        height: 30px;
        width: 150px;
    }
    button {
        height: 40px;
    }
}

.brackets_match.highlighted {
    border-color: #45FFA6;
    transition: all 300ms ease;
}

.brackets_matchNumber {
    font-size: 8px;
    text-align: center;
    letter-spacing: 1px;
    padding: 1px 5px;
    background-color: #404040;
    border: 1px solid #7a7a80;
    border-radius: 2px;
    position: absolute;
    top: -10px;
    left: 5px;
    box-shadow: 5px 6px 15px 5px rgba(0, 0, 0, 0.1);
}

.brackets_matchNumber.highlighted {
    border-color: #45FFA6;
    transition: all 300ms ease;
}

.brackets_opponents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
}

.brackets_opponent a {
    text-decoration: none;
    color: #bebecc;
}

.brackets_opponent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: default;
}

.opponentName {
    cursor: pointer;
}

.opponentName.selected {
    color: #45FFA6;
}

.opponentName.playerTeam {
    color: #10D4FF;
}

.brackets_default_score {
    color: #fff;
}

.brackets_win_score {
    color: #15cc74;
}

.brackets_loss_score {
    color: rgba(252, 35, 35, 0.872);;
} 

/* ------- robin ------- */

.bracketsRobin_match {
    padding: 5% 10% 1% 6%;
    border: 1px solid #7a7a80;
    border-radius: 5px;
    background-color: #404040;
    position: relative;
    width: 160px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    cursor: default;
}

.bracketsRobin_matchNumber {
    font-size: 8px;
    text-align: center;
    letter-spacing: 1px;
    padding: 1px 5px;
    background-color: #404040;
    border: 1px solid #7a7a80;
    border-radius: 2px;
    position: absolute;
    top: -10px;
    left: 5px;
    cursor: default;
    box-shadow: 5px 6px 15px 5px rgba(0, 0, 0, 0.1);
}

.bracketsRobin_opponents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    cursor: default;
}

.bracketsRobin_opponent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: default;
}

.bracketsRobin_default_score {
    color: #fff;
}

.bracketsRobin_win_score {
    color: #45FFA6;
}

.bracketsRobin_loss_score {
    color: red;
}