.roundRobin_resultGrid_container {
    width: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    background-color: #404040;
    border-radius: 5px;
    cursor: default;
}

.roundRobin_resultGrid_header_container {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #7a7a80;
    padding: 2% 2%;
    cursor: pointer;
}

.roundRobin_resultGrid_header {
    width: 9%;
    font-size: 10px;
    display: flex;
    justify-content: center;
}

.roundRobin_resultGrid_resultContainer {
    width: 100%;
    font-size: 10px;
    padding: 2% 2%;
}

.roundRobin_resultGrid_teamContainer {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.roundRobin_teamStat {
    width: 9%;
    font-size: 10px;
    display: flex;
    justify-content: center;
}

.roundRobin_teamStat.teamName {
    cursor: pointer;
    transition: all 500ms ease;
    &:hover {
        color: #45FFA6;
    }
}

.roundRobin_teamStat.highlight {
    color: #45FFA6;
}