.main_container {
    width: 100%;
    height: 100vh;
    text-align: center;
    padding: 1% 5%;
    align-content: center;
    transform: translate(0, -10vh);
}

.games_title {
    font-size: 60px;
    font-family: Nasalization Rg;
    margin-left: 2%;
    display: flex;
}

::-webkit-scrollbar {
    width: 0;
}

.main_NEVA {
    font-family: Alien Energy Rg;
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.neva_text {
    width: 100%;
    font-size: 300px !important;
    color: #10D4FF;
    text-shadow: rgba(218, 218, 218, 0.801) 3px 0 4px;
    line-height: 300px !important;
}

.main_NEVA p {
    font-size: 200%;
    color: #D1D1D1;
    text-transform: uppercase;
    margin-bottom: 0;
}

.rotating_text {
    font-family: Nasalization Rg;
    font-size: 250%;
    color: #D1D1D1;

    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;

    margin-top: 10%;
    padding: 0 0;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gardient_animation_text {
    font-family: MyFont Rg;
    font-size: 400%;
    text-transform: uppercase;

    width: 100%;

    margin-top: 8%;

    background: linear-gradient(90deg,
            #374177, #374177, #374177,
            #45FFA6, #45FFA6, #45FFA6,
            rgba(80, 60, 128, 0.753), rgba(80, 60, 128, 0.753), rgba(80, 60, 128, 0.753),
            #10D4FF, #10D4FF, #10D4FF);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    animation: gradient 15s ease infinite;
}

.news_container {
    margin-top: 3%;
}

.NEVA_svg {
    width: 85%;
}

.tournaments_container {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    width: 100%;
    backdrop-filter: blur(50px);
    box-shadow: 1px 6px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
}

.tournaments-container {
    width: 100%;
    display: flex;
}

.tournaments_games_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tournaments_games_container_empty {
    font-family: Nasalization Rg;
    margin-left: 30px;
}

.tournaments_games {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
   
}

.tournaments_games_link {
    color: #FFF;
    text-align: right;
    font-family: Proxima Nova Rg;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 700ms ease;
    margin-right: 7%;
    cursor: pointer;
    letter-spacing: 2px;
    &:hover {
        color: #45FFA6;
    }
}

.main-mini-game-container {
    display: flex;
    flex-direction: column;
    max-height: 315px;
    overflow-y: auto;
    padding-right: 5px;
}

.main-mini-game-container::-webkit-scrollbar {
    width: 5px;
    height: 0.01px;
}

.main-mini-game-container::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom right, #f1f1f1 0%, #fafafa 100%);
    border-radius: 30px;
}

.main-mini-game-container::-webkit-scrollbar-track:horizontal {
    background-color: white;
    box-shadow: inset 0 0 2px 2px gainsboro;
}

.main-mini-game-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
}

.main-mini-game-card {
    display: flex;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 100%), lightgray 50% / cover no-repeat;
    background-position: center;
    width: 300px;
    min-height: 74px;
    border-radius: 5px;
    margin-bottom: 2%;
    border-radius: 15px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 100%), lightgray 50% / cover no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: c;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
    padding: 0 0 1.2% 2%;
    border: 1px solid transparent;
}

.main-mini-game-card.selected {
    border: 1px solid #45FFA6;
}

.main-mini-game-card-title {
    color: #FFF;
    font-family: Proxima Nova Rg;
    font-style: normal;
    font-weight: 700;
    line-height: 92.8%;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;
}

.spinner_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pagination_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    min-height: 340px;
}

.pagination_button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 10px;
}

.pagination_button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}