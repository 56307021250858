.chosen_tournament_container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px;
    font-family: Nasalization Rg;
}

.chosen_tournament_info_tab_container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    font-size: 25px;
    align-items: center;
    justify-content: space-between;
    min-height: 450px;
}
.chosen_tournament_info_tab_container.staff {
    justify-content: flex-start;
}
.chosen_tournament_results{
    width: 80%;
}
.chosen_tournament_info_tab_container i {
    font-size: 18px;
    margin-top: 30px;
    color: rgb(128, 128, 128);
}

.chosen_tournament_teams_layer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 30px;
}

.chosen_tournament_short_stats {
    width: 100%;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chosen_tournament_stat_number {
    font-size: 40px;
    font-weight: 500;
}

.chosen_tournament_stat_letters {
    font-size: 12px;
    font-weight: 400;
    color: #45FFA6;
    cursor: default;
}

.chosen_tournament_main_info {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
}

.chosen_tournament_main_infoDescription {
    width: 100%;
    display: flex;
    justify-content:space-around;
}

.chosen_tournament_info {
    margin-top: 3%;
}

.chosen_tournament_info_staffContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.chosen_tournament_info_staff {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    gap: 15px;
    >h5 {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 25px;
    }
}

.chosen_tournament_info_staffMember {
    display: flex;
    align-items: center;
    gap: 40px;
    background-color: #222;
    padding: 10px 20px;
    border-radius: 10px;
    width: 80%;
    >img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 1px solid #45FFA6;
    }
    >div {
        font-size: 18x;
        font-weight: 300;
    }
}

.chosen_tournament_title {
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: space-between;
}

.chosen_tournament_titleInfo {
    padding: 0;
    margin: 0;
    width: 25%;
    font-size: 23px;
    font-weight: normal;
}

.chosen_tournament_title h3 {
    padding: 0;
    margin: 0;
    color: #45FFA6;
    font-size: 23px;
    font-weight: normal;
}

.chosen_tournament_titleInfo h3 {
    padding: 0;
    margin: 0;
    color: #45FFA6;
    font-size: 23px;
    font-weight: normal;
}

.chosen_tournament_titleInfo p {
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 23px;
    font-weight: normal;
}

.chosen_tournament_title p {
    padding: 0;
    margin: 0;
    font-size: 20px;
    color: #fff;
}

.chosen_tournament_main_info_slots {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress,
.progress-stacked {
    width: 70%;
    --bs-progress-height: 40px;
    --bs-progress-border-radius: 4px;
    border: 0;
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #444;
    --bs-progress-bar-bg: #45FFA6;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    overflow: hidden;
    background-color: #444;
    margin: 0;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition);
    box-shadow: 0px 0px 4px 0px #45FFA6;
}

.chosen_tournament_main_info_slots_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Nasalization Rg;
    font-size: 13px;
    margin-top: 1%;
}

/* --------------- HEADER ---------- */

.tournament_timetable_hours{
    font-size: 18px;
    margin: 10px 0 20px;
    color: rgb(131, 131, 131);
}
.headercard_container {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.chosen_game_info {
    display: flex;
    flex: 1;
    min-width: 0;
    min-height: 150px;
    margin-right: 150px;
}

.chosen_game_short_info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chosen_game_socials_button_container {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.participate_tournament_button_container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    margin: 5% 0;
}

.chosen_game_socials_button {
    font-size: 16px;
    border-radius: 5px;
    background-color: #222222;
    color: white;
    font-weight: 500;
    padding: 2px 7px;
    border: none;
    box-shadow: 0px 0px 4px 0px #45FFA6;
    margin-right: 10px;
    &:hover {
        background-color: #45FFA6;
        color: #222222;
        font-weight: 600;
    }
}

.chosen_game_socials_button.active_tab {
    background-color: #45FFA6;
    color: #222222;
    font-weight: 600;
}

.chosen_game_short_info_pic {
    width: 350px;
    height: 198px;
    border-radius: 10px;
    object-fit: cover;
}

.chosen_game_short_info_pic_contaner {
    position: relative;
    display: inline-block;
}

.chosen_game_short_info_pic_contaner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.chosen_game_name {
    font-size: 24px;
    font-weight: 800;
}


.chosen_game_genre {
    font-size: 18px;
    font-weight: 800;
    color: #777575;
}
.chosen_game_tags{
    display: flex;
    font-style: normal;
}

.chosen_game_socials {
    display: flex;
}

.chosen_game_socials_container {
    margin: 5px;
    cursor: pointer;
}

.chosen_game_socials_container a {
    text-decoration: none;
    color: #fff;
}

.chosen_game_short_stats {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 45px;
    text-align: center;
    min-width: 0;
}

.chosen_game_line {
    height: 110px;
    width: 10px;
    border-radius: 10px;
    background-color: #45FFA6;
}

.chosen_game_stat_number {
    font-size: 56px;
    font-weight: 900;
}

.chosen_game_stat_letters {
    font-size: 20px;
    font-weight: 700;
    color: #45FFA6;
}

.no_slots_text {
    font-size: 20px;
    border-radius: 11px;
    background-color: #343434;
    color: #45FFA6;
    font-weight: 500;
    padding: 15px 10px;
    border: none;
    text-transform: uppercase;
}

.save_tournament_button.participate {
    margin: 7% 0;
}