.breacket_mainContainer {
    width: 100%;
    max-height: 700px;
    padding: 25px;
    overflow: auto;
    backdrop-filter: blur(150px);
    border-radius: 8px;
    box-shadow: 1px 6px 15px 5px rgba(0, 0, 0, 0.1);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: relative;
}

.breacket_mainContainer_wrapper {
    width: 100%;
}

.stage_name {
    font-size: 35px;
    margin-bottom: 30px;
    margin-top: 50px;
    text-align: start;
    width: 100%;
}

.breacket_mainContainer::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.steppedline {
    box-shadow: 1px 6px 15px 5px rgba(0, 0, 0, 0.1);
} 

.steppedline_selected {
    position: relative;
    z-index: 2 !important;
    transition: all 300ms ease;
    border-top-color: #45FFA6 !important;
}

/* --------adminsButton-------- */

.adminsButton_container {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    gap: 30px;
    justify-content: flex-start;
}

.adminsButton_container button {
    background-color: #45FFA6;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 10px;
    color: #404040;
    border: 1px solid #45FFA6;
    text-align: center;
    width: 230px;
    transition: all 500ms ease;
    &:hover {
        color: #45FFA6;
        background-color: transparent;
    }
}

.bracketSavedSpinner {
    width: 35px;
    height: 35px;
}