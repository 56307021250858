.notification_center {
    position: absolute;
    top: calc(100% + 19px);
    right: -19px;
    width: 350px;
    height: 350px;
    background-color: rgb(236, 236, 236);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.191);
    border-radius: 8px;
    color: rgb(37, 37, 37);
}

.notification_center_switch {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 0.3px solid rgb(204, 202, 202);
}

.notification_center_switch div {
    padding: 3px;
    font-size: 13px;
    font-weight: 500;
    margin-left: 12px;
    border-bottom: 3px transparent solid;

    &:hover {
        cursor: pointer;
        font-weight: 900;
    }
}

.notification_center_switch div:not(:last-child) {
    border-right: 1px transparent solid;

}

.notif_tab_active {
    font-weight: 900 !important;
    border-bottom: 3px #45ffa594 solid !important;
}

.navbar_bell_container .notification_center *::selection {
    color: black;
}

.notification_center.visible {
    display: block;
}

.notification_center.hidden {
    display: none;
}

.notification_center_header {
    font-size: 17px;
    text-align: center;
    padding: 12px;
}

.notification_units_container {
    overflow-y: auto;
    height: 260px;
}

.notification_units_empty {
    font-size: 12px;
    padding: 15px;
}

.notification_units_container::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(227, 227, 227);
    border-radius: 0px 8px 8px 0;
}

.notification_units_container::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 10px 10px rgb(164, 164, 164);
    border: solid 1px transparent;
}

.notification_unit {
    width: 100%;
    padding: 8px;
    border-bottom: 0.3px solid #c2c2c2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(246, 246, 246);
    transition: opacity 0.5s ease, transform 0.5s ease, margin 0.5s ease;
    position: relative;

    &:hover {
        background-color: rgb(227, 227, 227);
    }
}

.notification_unit.fade-out {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
    height: 0;
    margin: 0;
    padding: 0;
}

.notification_date {
    font-size: 11px;
    color: #a8a8a8 !important;
    margin-top: 7px;
}

.notification_button {
    background-color: #64c5db;
    padding: 5px 10px;
    border-radius: 5px;
    color: black;
    border: none;
    width: 45%;
    align-self: center;
    margin: 7px 7px 0;
}

.notification_button a {
    all: unset;
    &:hover{
        all: unset;
    }
}

.notif_html {
    display: flex;
    flex-direction: column;
}

.notification_button.accept {
    background-color: #6ad87e;
}

.notification_button.decline {
    background-color: #dd6666;
}

.notification_delete {
    align-self: flex-end;
    background-color: transparent;
    font-size: 10px;
    color: #9f9f9f;

    &:hover {
        color: #686868;
    }
}

@keyframes fadeBackground {
    0% {
        background-color: rgba(242, 207, 207, 0.5);
    }

    100% {
        background-color: rgba(246, 246, 246, 1);
    }
}

.notification_unit.unread {
    animation: fadeBackground 3s forwards;
}