footer {
    background-color: #202020;
    font-family: 'Nasalization Rg';
    font-size: 17px;
    color: #c1c1c1;
    width: 100%;
    height: 5%;
    padding: 2% 5%;
    display: flex;
    -webkit-box-shadow: 0px -7px 11px 4px rgba(0, 0, 0, 0.366);
    -moz-box-shadow: 0px -7px 11px 4px rgba(0, 0, 0, 0.366);
    box-shadow: 0px -7px 11px 4px rgba(0, 0, 0, 0.366);
}


/*--------------------- LOGO BLOCK ---------------------*/


.footer_logo_block {
    width: 20%;
}


/*--------------------- SOCIALS BLOCK 1 ---------------------*/


.footer_links_main_block {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
}


/*--------------------- SOCIALS BLOCK 2 ---------------------*/


.footer_links_block2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer_links_block2 a {
    text-decoration: none; 
    color: #c1c1c1;
    transition: color 700ms ease;
    &:hover {
        color: #45FFA6;
    }
}


/*--------------------- LINKS BLOCK 3 ---------------------*/


.footer_links_block3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer_links_block3 a {
    text-decoration: none; 
    color: #c1c1c1;
    transition: color 700ms ease;
    &:hover {
        color: #45FFA6;
    }
}


/*--------------------- SOCIALS BLOCK ---------------------*/


.footer_links_socials_block {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer_links_socials {
    width: 100%;
    display: flex;
    gap: 15px;
}

.footer_links_socials_block a {
    text-decoration: none; 
    color: #c1c1c1;
    transition: color 700ms ease;
    cursor: pointer;
    &:hover {
        color: #45FFA6;
    }
}


/*--------------------- MEDIA ---------------------*/


@media (max-width: 900px) {
    footer {
        flex-direction: column;
    }
    
    .footer_logo_block {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 40px;
    }
    
    .footer_links_main_block {
        width: 100%;
        flex-direction: column;
        font-size: 13px;
        display: flex;
        align-items: flex-start;
        gap: 15px;
        margin: 30px 0 15px 0;
    }
    
    .footer_links_socials_block {
        gap: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    
    .footer_links_socials {
        justify-content: start;
    }
}